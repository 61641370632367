import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/qrtest',
    component: loadable(() => import('pages/qrtest')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard/alpha',
    component: loadable(() => import('pages/dashboard/alpha')),
  },
  {
    path: '/dashboard/beta',
    component: loadable(() => import('pages/dashboard/beta')),
  },
  {
    path: '/dashboard/gamma',
    component: loadable(() => import('pages/dashboard/gamma')),
  },

  // Edification
  {
    path: '/Maintainers/Edification/StudentProfilePage',
    component: loadable(() => import('pages/Maintainers/Edification/StudentProfilePage')),
  },
  {
    path: '/Maintainers/Edification/ProfesorProfilePage',
    component: loadable(() => import('pages/Maintainers/Edification/ProfesorProfilePage')),
  },
  {
    path: '/Maintainers/Edification/ProjectPage',
    component: loadable(() => import('pages/Maintainers/Edification/ProjectPage')),
  },
  {
    path: '/Maintainers/Edification/ActivityStatisticsPage',
    component: loadable(() => import('pages/Maintainers/Edification/ActivityStatisticsPage')),
  },
  {
    path: '/Maintainers/Edification/ActivityResultsPage',
    component: loadable(() => import('pages/Maintainers/Edification/ActivityResultsPage')),
  },
  {
    path: '/Maintainers/Edification/StudentActivityResultsPage',
    component: loadable(() => import('pages/Maintainers/Edification/StudentActivityResultsPage')),
  },
  {
    path: '/Maintainers/Edification/ProfesorActivityResultsPage',
    component: loadable(() => import('pages/Maintainers/Edification/ProfesorActivityResultsPage')),
  },
  {
    path: '/Maintainers/Edification/LearningObjectivePage',
    component: loadable(() => import('pages/Maintainers/Edification/LearningObjectivePage')),
  },
  {
    path: '/Maintainers/Edification/StudentProjectPage',
    component: loadable(() => import('pages/Maintainers/Edification/StudentProjectPage')),
  },
  {
    path: '/Maintainers/Edification/UniversalProjectPage',
    component: loadable(() => import('pages/Maintainers/Edification/UniversalProjectPage')),
  },
  {
    path: '/Maintainers/Edification/StudentListPage',
    component: loadable(() => import('pages/Maintainers/Edification/StudentListPage')),
  },
  {
    path: '/Maintainers/Edification/StudentDetailPage',
    component: loadable(() => import('pages/Maintainers/Edification/StudentDetailPage')),
  },
  {
    path: '/Maintainers/Edification/SessionDetailPage',
    component: loadable(() => import('pages/Maintainers/Edification/SessionDetailPage')),
  },
  {
    path: '/Maintainers/Edification/ActivitySessionsPage',
    component: loadable(() => import('pages/Maintainers/Edification/ActivitySessionsPage')),
  },
  {
    path: '/Maintainers/Edification/ActivitySessionDetailPage',
    component: loadable(() => import('pages/Maintainers/Edification/ActivitySessionDetailPage')),
  },
  {
    path: '/Maintainers/Edification/UsersMaintainerPage',
    component: loadable(() => import('pages/Maintainers/Edification/UsersMaintainerPage')),
  },
  // Internal
  {
    path: '/Maintainers/Internal/ProjectsPage',
    component: loadable(() => import('pages/Maintainers/Internal/ProjectsPage')),
  },
  {
    path: '/Maintainers/Internal/UsersPage',
    component: loadable(() => import('pages/Maintainers/Internal/UsersPage')),
  },
  {
    path: '/Maintainers/Internal/GeneratorsPage',
    component: loadable(() => import('pages/Maintainers/Internal/GeneratorsPage')),
  },
  {
    path: '/Maintainers/Internal/MetricsPage',
    component: loadable(() => import('pages/Maintainers/Internal/MetricsPage')),
  },
  {
    path: '/Maintainers/Internal/ProjectHealthPage',
    component: loadable(() => import('pages/Maintainers/Internal/ProjectHealthPage')),
  },
  // Maintainers
  {
    path: '/Maintainers/UniversalUsersPage',
    component: loadable(() => import('pages/Maintainers/UniversalUsersPage')),
  },
  {
    path: '/Maintainers/UniversalSesionsPage',
    component: loadable(() => import('pages/Maintainers/UniversalSesionsPage')),
  },
  {
    path: '/Maintainers/UniversalSessionsPage',
    component: loadable(() => import('pages/Maintainers/UniversalSessionsPage')),
  },
  {
    path: '/Maintainers/UniversalSessionDetailPage',
    component: loadable(() => import('pages/Maintainers/UniversalSessionDetailPage')),
  },
  {
    path: '/Maintainers/UsersPage',
    component: loadable(() => import('pages/Maintainers/UsersPage')),
  },
  {
    path: '/Maintainers/UserTrips',
    component: loadable(() => import('pages/Maintainers/UserTrips')),
  },
  {
    path: '/Maintainers/UserTripProducts',
    component: loadable(() => import('pages/Maintainers/UserTripProducts')),
  },
  {
    path: '/Maintainers/Sales/ProductsCollections',
    component: loadable(() => import('pages/Maintainers/Sales/ProductsCollections')),
  },
  {
    path: '/Maintainers/ProductsPage',
    component: loadable(() => import('pages/Maintainers/ProductsPage')),
  },
  {
    path: '/Maintainers/Sales/ProductsPage',
    component: loadable(() => import('pages/Maintainers/Sales/ProductsPage')),
  },
  {
    path: '/Maintainers/Learning/CasesPage',
    component: loadable(() => import('pages/Maintainers/Learning/CasesPage')),
  },
  {
    path: '/Maintainers/Learning/BasicCasesPage',
    component: loadable(() => import('pages/Maintainers/Learning/BasicCasesPage')),
  },
  {
    path: '/Maintainers/Learning/QuestionsPage',
    component: loadable(() => import('pages/Maintainers/Learning/QuestionsPage')),
  },
  {
    path: '/Maintainers/Learning/SequencePage',
    component: loadable(() => import('pages/Maintainers/Learning/SequencePage')),
  },
  {
    path: '/Maintainers/Learning/SequenceSelectorPage',
    component: loadable(() => import('pages/Maintainers/Learning/SequenceSelectorPage')),
  },
  {
    path: '/Maintainers/Learning/SequenceDetailPage',
    component: loadable(() => import('pages/Maintainers/Learning/SequenceDetailPage')),
  },
  {
    path: '/Maintainers/Learning/TriggersPage',
    component: loadable(() => import('pages/Maintainers/Learning/TriggersPage')),
  },
  {
    path: '/Maintainers/EnReserva/BoardDetailPage',
    component: loadable(() => import('pages/Maintainers/EnReserva/BoardDetailPage')),
  },
  {
    path: '/Maintainers/EnReserva/BoardsPage',
    component: loadable(() => import('pages/Maintainers/EnReserva/BoardsPage')),
  } /*

  {
    path: '/Maintainers/TripsPage',
    component: loadable(() => import('pages/Maintainers/TripsPage')),
  },
  {
    path: '/Maintainers/ScoresPage',
    component: loadable(() => import('pages/Maintainers/ScoresPage')),
  },
  */,
  // Learning
  {
    path: '/Maintainers/ToDelete/CaseGenerator',
    component: loadable(() => import('pages/Maintainers/ToDelete/CaseGenerator')),
  },
  {
    path: '/Maintainers/Learning/UsersAdminPage',
    component: loadable(() => import('pages/Maintainers/Learning/UsersAdminPage')),
  },
  {
    path: '/Maintainers/Learning/UsersPage',
    component: loadable(() => import('pages/Maintainers/Learning/UsersPage')),
  },
  {
    path: '/Maintainers/Learning/CoursesPage',
    component: loadable(() => import('pages/Maintainers/Learning/CoursesPage')),
  },
  {
    path: '/Maintainers/Learning/StudentsPage',
    component: loadable(() => import('pages/Maintainers/Learning/StudentsPage')),
  },
  {
    path: '/Maintainers/Learning/StudentDetailPage',
    component: loadable(() => import('pages/Maintainers/Learning/StudentDetailPage')),
  },
  {
    path: '/Maintainers/Learning/StudentConstructionPage',
    component: loadable(() => import('pages/Maintainers/Learning/StudentConstructionPage')),
  },
  {
    path: '/Maintainers/Learning/MachinesPage',
    component: loadable(() => import('pages/Maintainers/Learning/MachinesPage')),
  },
  {
    path: '/Maintainers/Learning/MachineParts',
    component: loadable(() => import('pages/Maintainers/Learning/MachineParts')),
  },
  {
    path: '/Maintainers/Learning/VariablesPage',
    component: loadable(() => import('pages/Maintainers/Learning/VariablesPage')),
  },
  // Padadini: crud para administrar usuarios por tiempo y Sesiones
  {
    path: '/Maintainers/Padadini/UsersPage',
    component: loadable(() => import('pages/Maintainers/Padadini/UsersPage')),
  },
  {
    path: '/Maintainers/Padadini/StudentDetailPage',
    component: loadable(() => import('pages/Maintainers/Padadini/StudentDetailPage')),
  },
  // Volcan
  {
    path: '/Maintainers/Sales/PicturesPage',
    component: loadable(() => import('pages/Maintainers/Sales/PicturesPage')),
  },
  {
    path: '/Maintainers/Sales/TipsPage',
    component: loadable(() => import('pages/Maintainers/Sales/TipsPage')),
  },
  {
    path: '/Maintainers/Sales/InstallersPage',
    component: loadable(() => import('pages/Maintainers/Sales/InstallersPage')),
  },
  {
    path: '/Maintainers/Sales/EventsPage',
    component: loadable(() => import('pages/Maintainers/Sales/EventsPage')),
  },
  {
    path: '/Maintainers/Sales/ZonesPage',
    component: loadable(() => import('pages/Maintainers/Sales/ZonesPage')),
  },
  {
    path: '/Maintainers/Sales/StepsPage',
    component: loadable(() => import('pages/Maintainers/Sales/StepsPage')),
  },
  {
    path: '/Maintainers/Sales/StepsDetailPage',
    component: loadable(() => import('pages/Maintainers/Sales/StepsDetailPage')),
  },
  // Apps
  {
    path: '/Apps/Learning/Gastronomia/Construccion',
    component: loadable(() => import('pages/Apps/Learning/Gastronomia/Construccion')),
  },
  {
    path: '/Apps/Learning/Gastronomia/FlujoTrabajo',
    component: loadable(() => import('pages/Apps/Learning/Gastronomia/FlujoTrabajo')),
  },
  {
    path: '/Apps/Learning/Logistics/Operacion/Recepcion',
    component: loadable(() => import('pages/Apps/Learning/Logistics/Operacion/Recepcion')),
  },
  {
    path: '/Apps/Learning/Logistics/PartesPiezas',
    component: loadable(() => import('pages/Apps/Learning/Logistics/PartesPiezas')),
  },
  {
    path: '/Apps/Learning/Logistics/ConstruccionLogistica',
    component: loadable(() => import('pages/Apps/Learning/Logistics/ConstruccionLogistica')),
  },
  {
    path: '/Apps/Learning/Gastronomia/Elaboracion',
    component: loadable(() => import('pages/Apps/Learning/Gastronomia/Elaboracion')),
  },
  {
    path: '/Apps/Learning/Gastronomia/Vestimenta',
    component: loadable(() => import('pages/Apps/Learning/Gastronomia/Vestimenta')),
  },
  {
    path: '/Apps/Learning/Logistics/Equipment',
    component: loadable(() => import('pages/Apps/Learning/Logistics/Equipment')),
  },
  {
    path: '/Apps/Learning/Logistics/DesignOperation',
    component: loadable(() => import('pages/Apps/Learning/Logistics/DesignOperation')),
  },
  {
    path: '/Apps/Learning/Logistics/Operacion/ClasificacionAlmacenaje',
    component: loadable(() =>
      import('pages/Apps/Learning/Logistics/Operacion/ClasificacionAlmacenaje'),
    ),
  },
  {
    path: '/Apps/Learning/Logistics/Operacion/Despacho',
    component: loadable(() => import('pages/Apps/Learning/Logistics/Operacion/Despacho')),
  },
  {
    path: '/Apps/Learning/Logistics/Operacion/Picking',
    component: loadable(() => import('pages/Apps/Learning/Logistics/Operacion/Picking')),
  },
  {
    path: '/Apps/Learning/Logistics/Operacion/Recepcion',
    component: loadable(() => import('pages/Apps/Learning/Logistics/Operacion/Recepcion')),
  },
  {
    path: '/Apps/Learning/Edification/Vestimenta',
    component: loadable(() => import('pages/Apps/Learning/Edification/Vestimenta')),
  },
  {
    path: '/Apps/Learning/Edification/PartesyPiezas',
    component: loadable(() => import('pages/Apps/Learning/Edification/PartesyPiezas')),
  },
  {
    path: '/Apps/Learning/Edification/Construccion',
    component: loadable(() => import('pages/Apps/Learning/Edification/Construccion')),
  },
  {
    path: '/Apps/Learning/Riego/Bodega',
    component: loadable(() => import('pages/Apps/Learning/Riego/Bodega')),
  },
  {
    path: '/Apps/Learning/Riego/PartesPiezas',
    component: loadable(() => import('pages/Apps/Learning/Riego/PartesPiezas')),
  },
  {
    path: '/Apps/Learning/Riego/Montaje',
    component: loadable(() => import('pages/Apps/Learning/Riego/Montaje')),
  },
  {
    path: '/Apps/Learning/Riego/FlujoTrabajo',
    component: loadable(() => import('pages/Apps/Learning/Riego/FlujoTrabajo')),
  },
  {
    path: '/Apps/Learning/dhl/incoterms',
    component: loadable(() => import('pages/Apps/Learning/dhl/incoterms')),
  },
  {
    path: '/Apps/Learning/dhl/onboarding',
    component: loadable(() => import('pages/Apps/Learning/dhl/onboarding')),
  },
  {
    path: '/Apps/Learning/Alimentacion/Bodega',
    component: loadable(() => import('pages/Apps/Learning/Alimentacion/Bodega')),
  },
  {
    path: '/Apps/Learning/Alimentacion/Cocina',
    component: loadable(() => import('pages/Apps/Learning/Alimentacion/Cocina')),
  },
  {
    path: '/Apps/Learning/Alimentacion/Comedor',
    component: loadable(() => import('pages/Apps/Learning/Alimentacion/Comedor')),
  },
  {
    path: '/Apps/Learning/Riesgos/AguasAndinas',
    component: loadable(() => import('pages/Apps/Learning/Riesgos/AguasAndinas')),
  },
  // Construccion Demo
  {
    path: '/Apps/Learning/Logistics/ConstruccionDemo',
    component: loadable(() => import('pages/Apps/Learning/Logistics/ConstruccionDemo')),
  },
  // Test Apps
  {
    path: '/Apps/Learning/Test/Test1',
    component: loadable(() => import('pages/Apps/Learning/Test/Test1')),
  },
  // Reportes externos
  {
    path: '/External/AgrotecReport',
    component: loadable(() => import('pages/External/AgrotecReport')),
  },
  {
    path: '/External/ElogosReport',
    component: loadable(() => import('pages/External/ElogosReport')),
  },
  {
    path: '/External/AlimentacionReport',
    component: loadable(() => import('pages/External/AlimentacionReport')),
  },
  {
    path: '/External/RiggerReport',
    component: loadable(() => import('pages/External/RiggerReport')),
  },
  {
    path: '/External/EssbioReport',
    component: loadable(() => import('pages/External/EssbioReport')),
  },
  {
    path: '/External/AguasAndinasReport',
    component: loadable(() => import('pages/External/AguasAndinasReport')),
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
