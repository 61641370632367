import antdData from 'antd/lib/locale-provider/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project management',
  'topBar.typeToSearch': 'Type to search',
  'topBar.buyNow': 'Buy package',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit profile',
  'topBar.profileMenu.logout': 'Logout',
  'breadcrumb.home': 'Home',

  'alpha.activeUsers': 'Active users',
  'alpha.totalUsers': 'Total users',
  'alpha.addedProducts': 'Added products',
  'alpha.topModels': 'Top models',
  'alpha.topUsers': 'Top users',
  'alpha.versionLog': 'Version log',
  'alpha.thisWeek': 'This week',
  'alpha.thisMonth': 'This month',
  'alpha.last3Months': 'Last three months',
  'alpha.more': 'More',
  'alpha.sesPerWeek': 'Sesions per week',
  'alpha.meanTimePerSes': 'Mean time per sesion',
  'alpha.sessions': 'Sessions',
  'alpha.givenSessions': 'Sesisions played',
  'alpha.generalMax': 'Max score (all users)',
  'alpha.generalAvg': 'Mean score (all users)',

  'menu.users': 'Users',
  'menu.models': 'Models',
  'menu.products': 'Products',
  'menu.projects': 'Projects',
  'menu.project': 'Project',
  'menu.courses': 'Course',

  'table.name': 'Name',
  'table.company': 'Company',
  'table.email': 'Email',
  'table.sessions': 'Sessions',
  'table.sesMean': 'Mean score per session',
  'table.lastAccess': 'Last access',
  'table.photo': 'Photo',
  'table.category': 'Category',
  'table.profile': 'Profile',
  'table.visits': 'Visits',
  'table.visitors': 'Visitors',
  'table.ratioPerUser': 'Ratio per user',
  'table.user': 'User',
  'table.products': 'Products',
  'table.action': 'Action',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.deleteSure': 'Are you sure?',
  'table.seeResults': 'See results',
  'table.password': 'Password',

  'global.download': 'Download',
  'global.fullscreen': 'Full screen',

  'users.title': 'User list',
  'users.addUser': 'Add user',
  'users.editUser': 'Edit user',
  'models.title': 'Model list',
  'products.title': 'Product list',
  'allTrips.title': 'Project list',
  'userTrips.title': 'project list',
  'userTripProducts.title': "Project's product list",
  'studentProjectPage.title': 'Activity list',

  'studentDetail.breadcrumb': 'Student details',
  'studentDetail.title': 'Session list for',

  'sessions.sessions': 'Sessions:',

  'modal.help.name': 'Type user name',
  'modal.help.email': "Type email adress. This will be used for the app's login",
  'modal.help.password': 'Type password. It must have at least 6 characters',

  'login.support': 'Support',
  'login.back': 'Back to Imova',
  'login.title': 'VR/AR SOLUTIONS MANAGER',
  'login.emailHelp': 'Type your email adress',
  'login.passHelp': 'Type your password',
  'login.rememberMe': 'Remember me in this device',
  'login.forgot': 'Forgot your password?',
  'login.button': 'Login',

  'forgot.title': 'Generate new password',
  'forgot.back': 'Back to login',
  'forgot.button': 'Generate new password',

  'notification.denied': 'Access denied',
  'notification.deniedMessage': 'Username or password not correct',
  'notification.invalidMail': 'Invalid email address',
  'notification.invalidMailMessage': 'not registered or deleted. Please retry.',
  'notification.granted': 'Access granted',
  'notification.grantedMessage': 'You have successfully logged in to the VR/AR Solutions Manager',
  'notification.validMail': 'Valid email',
  'notification.validMailMessage': 'We have sent a password recovery email to',

  PuntajeGlobal: 'Global score',
  PuntajePromedio: 'Mean score',
  TiempoPromedio: 'Mean time',
  SesionesUsadas: 'Used sessions',
  SesionesContratadas: 'Available sessions',
  TimestampInicio: 'Valid from',
  TimestampFinal: 'Valid until',
  Promedio: 'Mean score',
  Notas: 'Sessions',
  LastLogin: 'Last login',
  user: 'Email',
  Timestamp: 'Valid from',
  CantidadSesiones: 'Sessions',
  perfil: 'Profile description',
  estado: 'Profile state',
  Count: 'Sessions',
  Meantime: 'Mean time per session',
  Compania: 'Company',
  ConteoSesiones: 'Sessions Count',
  ClientesPerdidos: 'Lost clients',
  PreguntasCorrectas: 'Correct answers',
  PreguntasIncorrectas: 'Incorrect answers',

  Nombre: 'Name',
  Puntaje: 'Score',
  Sesiones: 'Sessions',
  Fecha: 'Date',

  Actividad: 'Activity',
  Sección: 'Section',
  Estado: 'State',
  Habilitado: 'Available',
  Duración: 'Duration',

  back: 'Back',
}

export default {
  locale: 'en-US',
  antdData,
  messages,
}
