import antdData from 'antd/lib/locale-provider/es_ES'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Administración del proyecto',
  'topBar.typeToSearch': 'Escriba para buscar...',
  'topBar.buyNow': 'Comprar paquete $26',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hola',
  'topBar.profileMenu.billingPlan': 'Plan de pago',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Editar Perfil',
  'topBar.profileMenu.logout': 'Cerrar sesión',
  'breadcrumb.home': 'Inicio',

  'alpha.activeUsers': 'Usuarios Activos',
  'alpha.totalUsers': 'Usuarios Totales',
  'alpha.addedProducts': 'Productos Agregados',
  'alpha.topModels': 'Productos más visitados',
  'alpha.topUsers': 'Usuarios más frecuentes',
  'alpha.versionLog': 'Registro de versiones',
  'alpha.thisWeek': 'Esta semana',
  'alpha.thisMonth': 'Este mes',
  'alpha.last3Months': 'Últimos 3 meses',
  'alpha.more': 'Ver más',
  'alpha.sesPerWeek': 'Sesiones por semana',
  'alpha.meanTimePerSes': 'Tiempo promedio por visita',
  'alpha.sessions': 'Sesiones',
  'alpha.givenSessions': 'Sesiones Impartidas',
  'alpha.generalMax': 'Puntaje Máximo General',
  'alpha.generalAvg': 'Promedio General',
  'alpha.totalProjects': 'Proyectos en Total',
  'alpha.activeProjects': 'Proyectos Activos',

  'menu.users': 'Usuarios',
  'menu.models': 'Modelos',
  'menu.products': 'Productos',
  'menu.projects': 'Proyectos',
  'menu.project': 'Proyecto',
  'menu.courses': 'Cursos',

  'table.name': 'Nombre',
  'table.id': 'ID o número de identificación',
  'table.company': 'Compañía',
  'table.email': 'Email',
  'table.sessions': 'Sesiones',
  'table.sesMean': 'Promedio por sesión',
  'table.lastAccess': 'Último acceso',
  'table.photo': 'Foto',
  'table.category': 'Categoría',
  'table.profile': 'Ficha',
  'table.visits': 'Visitas',
  'table.visitors': 'Visitantes',
  'table.ratioPerUser': 'Ratio por usuario',
  'table.user': 'Usuario',
  'table.products': 'Productos',
  'table.action': 'Acción',
  'table.edit': 'Editar',
  'table.delete': 'Eliminar',
  'table.deleteSure': '¿Desea eliminar?',
  'table.seeResults': 'Ver Resultados',
  'table.password': 'Contraseña',
  'table.project': 'Proyecto',

  'global.download': 'Descargar',
  'global.fullscreen': 'Pantalla Completa',

  'users.title': 'Lista de Usuarios',
  'users.addUser': 'Agregar Usuarios',
  'users.editUser': 'Editar Usuario',
  'users.loadUsers': 'Carga Masiva',
  'models.title': 'Lista de Modelos',
  'products.title': 'Lista de Productos',
  'allTrips.title': 'Lista de todos los proyectos',
  'userTrips.title': 'Lista de proyectos de',
  'userTripProducts.title': 'Lista de productos del proyecto',
  'studentProjectPage.title': 'Lista de actividades',
  'studentDetailPage.title': 'Detalle de Estudiantes',

  'sessions.sessions': 'Sesiones de: ',

  'modal.help.name': 'Ingrese nombre del nuevo usuario',
  'modal.help.id': 'Ingrese el identificador para el usuario',
  'modal.help.email': 'Ingrese email del usuario. Éste servirá para ingresar a la aplicación',
  'modal.help.password': 'Ingrese contraseña del usuario. Debe tener 6 caracteres o más',

  'login.support': 'Soporte',
  'login.back': 'Volver a Imova',
  'login.title': 'ADMINISTRADOR DE SOLUCIONES VR/AR',
  'login.emailHelp': 'Ingrese su email',
  'login.passHelp': 'Ingrese su contraseña',
  'login.projectHelp': 'Seleccione proyecto',
  'login.rememberMe': 'Recordarme en este equipo',
  'login.forgot': '¿Olvidó su contraseña?',
  'login.button': 'Ingresar',

  'forgot.title': 'SOLICITE UNA NUEVA CONTRASEÑA',
  'forgot.back': 'Volver al login',
  'forgot.button': 'Solicitar cambio de contraseña',

  'notification.denied': 'Acceso denegado',
  'notification.deniedMessage': 'El nombre de usuario o contraseña no son válidos.',
  'notification.invalidMail': 'Email no válido',
  'notification.invalidMailMessage':
    'no está registrado o ya ha sido borrado. Por favor, reintente.',
  'notification.granted': 'Acceso concedido',
  'notification.grantedMessage': 'Haz ingresado con éxito al Administrador de Soluciones VR/AR',
  'notification.validMail': 'Email válido',
  'notification.validMailMessage': 'Hemos enviado un mensaje de recuperación de contraseña a',

  // Nombre de indices en las columnas de tablas
  PuntajeGlobal: 'Puntaje Global',
  PuntajePromedio: 'Puntaje Promedio',
  TiempoPromedio: 'Tiempo Promedio',
  SesionesUsadas: 'Sesiones Usadas',
  SesionesContratadas: 'Sesiones Contratadas',
  TimestampInicio: 'Vigente Desde',
  TimestampFinal: 'Vigente Hasta',
  Promedio: 'Promedio de notas',
  Notas: 'Sesiones',
  LastLogin: 'Último Acceso',
  user: 'Email',
  Timestamp: 'Vigente Desde',
  CantidadSesiones: 'Sesiones',
  perfil: 'Descripción Perfil',
  estado: 'Estado Perfil',
  Count: 'Sesiones',
  Meantime: 'Promedio por sesión',
  Compania: 'Compañía',
  ConteoSesiones: 'Conteo Sesiones',
  ClientesPerdidos: 'Clientes Perdidos',
  PreguntasCorrectas: 'Preguntas Correctas',
  PreguntasIncorrectas: 'Preguntas Incorrectas',
  LeftMenu: 'Módulos cargados',

  Nombre: 'Nombre',
  Puntaje: 'Puntaje',
  Sesiones: 'Sesiones',
  Fecha: 'Fecha',
  Sesion: 'Sesión',
  Duración: 'Duración',

  Actividad: 'Actividad',
  Sección: 'Sección',
  Estado: 'Estado',
  Habilitado: 'Habilitado',

  ReportBug: 'Soporte',

  back: 'Volver',
  Attemps: 'Intentos',
}

export default {
  locale: 'es-ES',
  antdData,
  messages,
}
