import antdData from 'antd/lib/locale-provider/pt_BR'

const messages = {
  'topBar.issuesHistory': 'Histórico de problemas',
  'topBar.projectManagement': 'Gerenciamento de projetos',
  'topBar.typeToSearch': 'Digite para pesquisar',
  'topBar.buyNow': 'Comprar pacote',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Olá',
  'topBar.profileMenu.billingPlan': 'Plano de faturamento',
  'topBar.profileMenu.role': 'Função',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Telefone',
  'topBar.profileMenu.editProfile': 'Editar perfil',
  'topBar.profileMenu.logout': 'Logout',
  'breadcrumb.home': 'Início',

  'alpha.activeUsers': 'Usuários ativos',
  'alpha.totalUsers': 'Total de usuários',
  'alpha.addedProducts': 'Produtos adicionados',
  'alpha.topModels': 'Principais modelos',
  'alpha.topUsers': 'Principais usuários',
  'alpha.versionLog': 'Registro de versão',
  'alpha.thisWeek': 'Esta semana',
  'alpha.thisMonth': 'Este mês',
  'alpha.last3Months': 'Últimos três meses',
  'alpha.more': 'Mais',
  'alpha.sesPerWeek': 'Sessoes por semana',
  'alpha.meanTimePerSes': 'Tempo médio por sessão',
  'alpha.sessions': 'Sessões',
  'alpha.givenSessions': 'Sessões jogadas',
  'alpha.generalMax': 'Nota máxima (todos os usuários)',
  'alpha.generalAvg': 'Nota média (todos os usuários)',

  'menu.users': 'Usuários',
  'menu.models': 'Modelos',
  'menu.products': 'Produtos',
  'menu.projects': 'Projetos',
  'menu.project': 'Projeto',
  'menu.courses': 'Curso',

  'table.name': 'Nome',
  'table.company': 'Empresa',
  'table.email': 'Email',
  'table.sessions': 'Sessões',
  'table.sesMean': 'Nota média por sessão',
  'table.lastAccess': 'Último acesso',
  'table.photo': 'Foto',
  'table.category': 'Categoria',
  'table.profile': 'Perfil',
  'table.visits': 'Visitas',
  'table.visitors': 'Visitantes',
  'table.ratioPerUser': 'Razão por usuário',
  'table.user': 'Usuário',
  'table.products': 'Produtos',
  'table.action': 'Ação',
  'table.edit': 'Editar',
  'table.delete': 'Eliminar',
  'table.deleteSure': 'Tem certeza?',
  'table.seeResults': 'Veja os resultados',
  'table.password': 'Senha',

  'global.download': 'Download',
  'global.fullscreen': 'Tela completa',

  'users.title': 'Lista de usuários',
  'users.addUser': 'Adicionar usuário',
  'users.editUser': 'Editar usuário',
  'models.title': 'Lista de modelos',
  'products.title': 'Lista de produtos',
  'allTrips.title': 'Lista de projetos',
  'userTrips.title': 'lista de projetos',
  'userTripProducts.title': 'Lista de produtos do projeto',
  'studentProjectPage.title': 'Lista de atividades',

  'studentDetail.breadcrumb': 'Detalhe do aluno',
  'studentDetail.title': 'Lista de sessões do',

  'sessions.sessions': 'Sessões do:',

  'modal.help.name': 'Digite o nome do novo usuário',
  'modal.help.email': 'Digite o e-mail do usuário. Isso será usado para entrar no aplicativo',
  'modal.help.password': 'Digite a senha do usuário. Deve ter 6 caracteres ou mais',

  'login.support': 'Suporte',
  'login.back': 'Voltar para Imova',
  'login.title': 'Gerente de soluções VR/AR',
  'login.emailHelp': 'Digite seu e-mail',
  'login.passHelp': 'Digite seu senha',
  'login.rememberMe': 'Lembre-se de mim neste time',
  'login.forgot': '¿Esqueceu sua senha?',
  'login.button': 'Entrar',

  'forgot.title': 'Solicite uma nova senha',
  'forgot.back': 'Volte ao login',
  'forgot.button': 'Solicite uma nova senha',

  'notification.denied': 'Acesso negado',
  'notification.deniedMessage': 'O nome de usuário ou senha não é válido.',
  'notification.invalidMail': 'E-mail inválido',
  'notification.invalidMailMessage':
    'não está registrado ou já foi excluído. Por favor, tente novamente',
  'notification.granted': 'Acesso concedido',
  'notification.grantedMessage': 'Você fez login com sucesso no Gerente de soluções VR/AR',
  'notification.validMail': 'E-mail válido',
  'notification.validMailMessage': 'Enviamos uma mensagem de recuperação de senha para',

  PuntajeGlobal: 'Nota global',
  PuntajePromedio: 'Nota média',
  TiempoPromedio: 'Tempo médio',
  SesionesUsadas: 'Sessões usadas',
  SesionesContratadas: 'Sessões disponíveis',
  TimestampInicio: 'Válido desde',
  TimestampFinal: 'Válido até',
  Promedio: 'Nota média',
  Notas: 'Sessões',
  LastLogin: 'Último login',
  user: 'Email',
  Timestamp: 'Válido desde',
  CantidadSesiones: 'Sessões',
  perfil: 'Descrição do perfil',
  estado: 'Estado do perfil',
  Count: 'Sessões',
  Meantime: 'Tempo médio por sessão',
  Compania: 'Empresa',
  ConteoSesiones: 'Contar sessões',
  ClientesPerdidos: 'Clientes perdidos',
  PreguntasCorrectas: 'Respostas corretas',
  PreguntasIncorrectas: 'Respostas incorretas',

  Nombre: 'Nome',
  Puntaje: 'Nota',
  Sesiones: 'Sessões',
  Fecha: 'Data',

  Actividad: 'Atividade',
  Sección: 'Seção',
  Estado: 'Estado',
  Habilitado: 'Disponível',
  Duración: 'duração',

  back: 'Voltar',
}

export default {
  locale: 'pt-BR',
  antdData,
  messages,
}
