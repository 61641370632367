import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/pt'
import 'moment/locale/es'
import styles from './style.module.scss'

@connect(({ settings }) => ({ settings }))
class LanguageSelector extends React.Component {
  changeLang = ({ key }) => {
    moment.locale(key)
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }

  render() {
    const {
      settings: { locale },
    } = this.props
    const language = locale.substr(0, 2)

    let flag

    if (language === 'es') flag = '🇨🇱'
    else if (language === 'en') flag = '🇺🇸'
    else if (language === 'fr') flag = '🇫🇷'
    else if (language === 'pt') flag = '🇧🇷'

    const langMenu = (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
        <Menu.Item key="es-ES">
          <span role="img" aria-label="Español" className="mr-2">
            🇨🇱
          </span>
          Español
        </Menu.Item>
        <Menu.Item key="en-US">
          <span role="img" aria-label="English" className="mr-2">
            🇺🇸
          </span>
          English
        </Menu.Item>
        <Menu.Item key="fr-FR">
          <span role="img" aria-label="Français" className="mr-2">
            🇫🇷
          </span>
          Français
        </Menu.Item>
        <Menu.Item key="pt-BR">
          <span role="img" aria-label="Português" className="mr-2">
            🇧🇷
          </span>
          Português
        </Menu.Item>
        {/*
        <Menu.Item key="ru-RU">
          <span role="img" aria-label="Русский" className="mr-2">
            🇷🇺
          </span>
          Русский
        </Menu.Item>
        <Menu.Item key="zh-CN">
          <span role="img" aria-label="简体中文" className="mr-2">
            🇨🇳
          </span>
          简体中文
        </Menu.Item>
        */}
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <span role="img" className="mr-2">
            {flag}
          </span>
          <strong className="text-uppercase">{language}</strong>
        </div>
      </Dropdown>
    )
  }
}

export default LanguageSelector
