import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyAtPJ1OoBcv21c4c_AD-yF3zfaQxhg6Jsk',
  authDomain: 'reporteria-web.firebaseapp.com',
  databaseURL: 'https://reporteria-web.firebaseio.com',
  projectId: 'reporteria-web',
  storageBucket: 'reporteria-web.appspot.com',
  messagingSenderId: '824245382613',
}
global.secondary = firebase.initializeApp(firebaseConfig)

const firebaseApp = firebase.initializeApp(firebaseConfig, 'Universal')
// Al hacer F5, no reconoce la DB primaria, con este firebaseConfig ¿
global.originalDatabase = firebaseApp
const firebaseAuth = firebase.auth
export default firebaseApp

export async function login(email, password) {
  //  global.secondary.delete()
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(() => {
      notification.warning({
        message: 'Acceso Denegado',
        description: 'El nombre de usuario o contraseña no son válidos.',
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function askForMoreData(uid, firebaseConnection) {
  if (firebaseConnection === undefined) firebaseConnection = false
  const ref = firebase.database().ref(`Clients/${uid}`)
  // console.log(uid)
  global.uid = uid
  async function getUserData() {
    return new Promise(resolve => {
      ref.once('value', function ok(data) {
        // do some stuff once
        // Obtiene todo Clients/uid y lo asigna a primary
        let primary = data.val()
        // Si es multiproyecto, en firebaseConnection viene el proyecto escogido
        // Si primary es arreglo, se debe asignar el elemento del arreglo de dicho proyecto
        if (Array.isArray(primary)) {
          // Obtener projectId de firebaseConnection
          let projectId = ''
          if (firebaseConnection) ({ projectId } = firebaseConnection)
          else projectId = window.localStorage.getItem('selectedProject')
          // Buscar dentro de primary[n].Firebase el mismo projectId
          let primaryResponse = {}
          primary.map(projectItem => {
            if (projectItem.Firebase.projectId === projectId) primaryResponse = { ...projectItem }
            return true
          })
          // Retornar el primary[n] correspondiente
          primary = Object.assign({}, primaryResponse)
        }
        // si primary === null, es que el usuario existió y fue eliminado
        global.primary = primary
        resolve(getSecondaryData(primary))
        // resolve(data.val())
      })
    })
  }

  return getUserData()
}

export async function getSecondaryData(primary) {
  return new Promise(resolve2 => {
    if (primary.Proyecto !== 'Plataforma Interna' || true) {
      // Para los proyectos de clientes
      // Generar numero random para diferenciar instancias de secondary
      const rand = Math.random() * 100000
      console.log('getSecondaryData', primary)
      // ERROR: primary.Firebase trae arreglo. Se cae!
      const selectedProject = window.localStorage.getItem('selectedProject')
      if (Array.isArray(primary.Firebase)) {
        const firebaseData = primary.Firebase.find(item => item.projectId === selectedProject)
        global.secondary = firebase.initializeApp(firebaseData, selectedProject + rand)
      } else global.secondary = firebase.initializeApp(primary.Firebase, primary.Proyecto + rand)
      global.secondary
        .auth()
        .signInAnonymously()
        .then(() => {
          resolve2(primary)
        })
        .catch(function f() {
          // Handle Errors here.
          // ...
        })
    } else {
      // Para Plataforma Interna
      global.secondary = global.originalDatabase
      resolve2(primary)
    }
    // console.log(global.secondary)
  })
}

export async function forget(username) {
  // global.secondary.delete()
  return firebaseAuth()
    .sendPasswordResetEmail(username)
    .then(() => true)
    .catch(() => {
      notification.warning({
        message: 'Email no válido ',
        description: `${username} no está registrado o ya ha sido borrado. Por favor, reintente.`,
      })
    })
}

export async function logout() {
  console.log(global.secondary.name)
  // global.secondary.delete()
  return firebaseAuth()
    .signOut()
    .then(() => true)
}
