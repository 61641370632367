export async function getLeftMenuData() {
  return [
    {
      title: 'QRTest',
      key: 'qrtest',
      url: '/qrtest',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard/alpha',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Dashboard',
      key: 'dashboardBeta',
      url: '/dashboard/beta',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Dashboard',
      key: 'dashboardGamma',
      url: '/dashboard/gamma',
      icon: 'icmn icmn-stats-dots',
    },
    {
      divider: true,
    },
    {
      title: 'menu.users',
      key: 'users',
      url: '/Maintainers/UsersPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'menu.users',
      key: 'universalUsers',
      url: '/Maintainers/UniversalUsersPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'table.sessions',
      key: 'universalSesions',
      url: '/Maintainers/UniversalSesionsPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'table.sessions',
      key: 'universalSessions',
      url: '/Maintainers/UniversalSessionsPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Detalle de sesión',
      key: 'universalSessionDetail',
      url: '/Maintainers/UniversalSessionDetailPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'menu.products',
      key: 'products',
      url: '/Maintainers/ProductsPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'Editor de productos',
      key: 'CRUDproducts',
      url: '/Maintainers/Sales/ProductsPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'menu.projects',
      key: 'panels',
      url: '/Maintainers/UserTrips',
    },
    {
      title: 'Productos del proyecto',
      key: 'panelProds',
      url: '/Maintainers/UserTripProducts',
    },
    {
      title: 'menu.projects',
      key: 'ProductsCollections',
      url: '/Maintainers/Sales/ProductsCollections',
      icon: 'icmn icmn-folder-open',
    },
    // Edification
    {
      title: 'Perfil Estudiante',
      key: 'edificationstudentProfile',
      url: '/Maintainers/Edification/StudentProfilePage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Perfil Estudiante',
      key: 'edificationprofesorProfile',
      url: '/Maintainers/Edification/ProfesorProfilePage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'menu.project',
      key: 'edificationProject',
      url: '/Maintainers/Edification/ProjectPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'menu.project',
      key: 'edificationStudentProject',
      url: '/Maintainers/Edification/StudentProjectPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'menu.project',
      key: 'universalProject',
      url: '/Maintainers/Edification/UniversalProjectPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'Lista de Estudiantes',
      key: 'studentList',
      url: '/Maintainers/Edification/StudentListPage',
      icon: 'icmn icmn-file-text',
    },
    {
      title: 'studentDetailPage.title',
      key: 'studentDetailList',
      url: '/Maintainers/Edification/StudentDetailPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Detalle de Sesiones',
      key: 'sessionDetailList',
      url: '/Maintainers/Edification/SessionDetailPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Estadisticas de Actividades',
      key: 'activityStatistics',
      url: '/Maintainers/Edification/ActivityStatisticsPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Resultados de Actividades',
      key: 'activityResults',
      url: '/Maintainers/Edification/ActivityResultsPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Resultados de Actividad',
      key: 'studentActivityResults',
      url: '/Maintainers/Edification/StudentActivityResultsPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Actividad',
      key: 'activityUnity',
      url: '/Maintainers/Edification/ActivityUnityPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Objetivos de Aprendizaje',
      key: 'learningObjective',
      url: '/Maintainers/Edification/LearningObjectivePage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Sesiones de la Actividad',
      key: 'activitySessions',
      url: '/Maintainers/Edification/ActivitySessionsPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Puntajes de la Sesión',
      key: 'activitySessions',
      url: '/Maintainers/Edification/ActivitySessionDetailPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Resultados de Actividad',
      key: 'studentActivityResults',
      url: '/Maintainers/Edification/ProfesorActivityResultsPage',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Mantenedor de Usuarios',
      key: 'usersMaintainer',
      url: '/Maintainers/Edification/UsersMaintainerPage',
      icon: 'icmn icmn-users',
    },
    // Internal
    {
      title: 'Usuarios',
      key: 'internalUsers',
      url: '/Maintainers/Internal/UsersPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Proyectos',
      key: 'internalProjects',
      url: '/Maintainers/Internal/ProjectsPage',
      icon: 'icmn icmn-folder-open',
    },
    {
      title: 'Generadores',
      key: 'internalGenerators',
      url: '/Maintainers/Internal/GeneratorsPage',
      icon: 'icmn icmn-price-tags',
    },
    {
      title: 'Métricas',
      key: 'internalMetrics',
      url: '/Maintainers/Internal/MetricsPage',
      icon: 'icmn icmn-stats-bars',
    },
    {
      title: 'Salud de Proyecto',
      key: 'projectHealth',
      url: '/Maintainers/Internal/ProjectHealthPage',
      icon: 'icmn icmn-stats-bars',
    },
    // Learning
    {
      title: 'Usuarios',
      key: 'learningAdminUsers',
      url: '/Maintainers/Learning/UsersAdminPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Usuarios',
      key: 'learningUsers',
      url: '/Maintainers/Learning/UsersPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'menu.courses',
      key: 'learningCourses',
      url: '/Maintainers/Learning/CoursesPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Estudiantes',
      key: 'learningStudents',
      url: '/Maintainers/Learning/StudentsPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Detalle de Estudiante',
      key: 'learningStudentDetail',
      url: '/Maintainers/Learning/StudentDetailPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Detalle de Estudiante',
      key: 'constructionStudentDetail',
      url: '/Maintainers/Learning/StudentConstructionPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Máquinas y Zonas',
      key: 'learningMachines',
      url: '/Maintainers/Learning/MachinesPage',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Casos',
      key: 'casosCRUD',
      url: '/Maintainers/Learning/CasesPage',
      icon: 'icmn-drawer',
    },
    {
      title: 'Casos',
      key: 'casosBasic',
      url: '/Maintainers/Learning/BasicCasesPage',
      icon: 'icmn-drawer',
    },
    {
      title: 'Preguntas',
      key: 'questionsCRUD',
      url: '/Maintainers/Learning/QuestionsPage',
      icon: 'icmn-question',
    },
    {
      title: 'Secuencias',
      key: 'sequencePage',
      url: '/Maintainers/Learning/SequencePage',
      icon: 'icmn-list-numbered',
    },
    {
      title: 'Secuencias',
      key: 'sequenceDetailPage',
      url: '/Maintainers/Learning/SequenceDetailPage',
      icon: 'icmn-list-numbered',
    },
    {
      title: 'Triggers',
      key: 'triggersPage',
      url: '/Maintainers/Learning/TriggersPage',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Tableros',
      key: 'boardsCRUD',
      url: '/Maintainers/EnReserva/BoardsPage',
      icon: 'icmn icmn-table',
    },
    {
      title: 'Detalle de Tableros',
      key: 'boardsDetailCRUD',
      url: '/Maintainers/EnReserva/BoardDetailPage',
      icon: 'icmn icmn-table',
    },
    {
      title: 'Piezas',
      key: 'learningMachineParts',
      url: '/Maintainers/Learning/MachineParts',
    },
    {
      title: 'Variables de Juego',
      key: 'learningVariables',
      url: '/Maintainers/Learning/VariablesPage',
      icon: 'icmn icmn-equalizer',
    },
    // Padadini class
    {
      title: 'Usuarios',
      key: 'padadiniUsers',
      url: '/Maintainers/Padadini/UsersPage',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Detalle de Estudiante',
      key: 'padadiniStudentDetail',
      url: '/Maintainers/Padadini/StudentDetailPage',
      icon: 'icmn icmn-users',
    },
    // Volcan
    {
      title: 'Fotos Inspiracionales',
      key: 'inspirationalPictures',
      url: '/Maintainers/Sales/PicturesPage',
      icon: 'icmn-images',
    },
    {
      title: 'Tips',
      key: 'tips',
      url: '/Maintainers/Sales/TipsPage',
      icon: 'icmn-bubble2',
    },
    {
      title: 'Instaladores',
      key: 'installers',
      url: '/Maintainers/Sales/InstallersPage',
      icon: 'icmn-wrench',
    },
    {
      title: 'Eventos',
      key: 'events',
      url: '/Maintainers/Sales/EventsPage',
      icon: 'icmn-ticket',
    },
    {
      title: 'Zonas Geográficas',
      key: 'zones',
      url: '/Maintainers/Sales/ZonesPage',
      icon: 'icmn-location',
    },
    {
      title: 'Secuencias',
      key: 'stepsPage',
      url: '/Maintainers/Sales/StepsPage',
      icon: 'icmn-list-numbered',
    },
    {
      title: 'Asignador de Secuencias',
      key: 'sequenceSelectorPage',
      url: '/Maintainers/Learning/SequenceSelectorPage',
      icon: 'icmn-list-numbered',
    },
    {
      title: 'Detalle Secuencias',
      key: 'stepsDetailPage',
      url: '/Maintainers/Sales/StepsDetailPage',
      icon: 'icmn-list-numbered',
    },
    // Apps
    {
      title: 'menu.courses',
      key: 'courses',
      icon: 'icmn icmn-file-text',
      children: [
        {
          title: 'Incoterms',
          key: 'unityIncoterms',
          url: '/Apps/Learning/dhl/incoterms',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Onboarding',
          key: 'unityDHLOnboarding',
          url: '/Apps/Learning/dhl/onboarding',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Construcción de planta',
          key: 'construccionCocina',
          url: '/Apps/Learning/Gastronomia/Construccion',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Vestimenta y EPP',
          key: 'vestimentaCocina',
          url: '/Apps/Learning/Gastronomia/Vestimenta',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Elaboración de alimentos',
          key: 'elaboracionCocina',
          url: '/Apps/Learning/Gastronomia/Elaboracion',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Flujo de Trabajo',
          key: 'flujoTrabajoCocina',
          url: '/Apps/Learning/Gastronomia/FlujoTrabajo',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Generador de Casos',
          key: 'casosForm',
          url: '/Maintainers/ToDelete/CaseGenerator',
          icon: 'icmn icmn-pencil',
        },
        {
          title: 'Partes y Piezas',
          key: 'logisticPartesPiezas',
          url: '/Apps/Learning/Logistics/PartesPiezas',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Construcción Logística',
          key: 'logisticConstruccionLogistica',
          url: '/Apps/Learning/Logistics/ConstruccionLogistica',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Equipos y zonas',
          key: 'equipment',
          url: '/Apps/Learning/Logistics/Equipment',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Diseño y operación',
          key: 'designOperation',
          url: '/Apps/Learning/Logistics/DesignOperation',
          icon: 'icmn icmn-make-group',
        },
        {
          title: 'Construcción Demo',
          key: 'construccionDemo',
          url: '/Apps/Learning/Logistics/ConstruccionDemo',
          icon: 'icmn icmn-cogs',
        },
        {
          title: 'Bodega Riego',
          key: 'bodegaRiego',
          url: '/Apps/Learning/Riego/Bodega',
          icon: 'icmn icmn-make-group',
        },
        {
          title: 'Partes y Piezas',
          key: 'partesPiezasRiego',
          url: '/Apps/Learning/Riego/PartesPiezas',
          icon: 'icmn icmn-make-group',
        },
        {
          title: 'Montaje',
          key: 'montajeRiego',
          url: '/Apps/Learning/Riego/Montaje',
          icon: 'icmn icmn-make-group',
        },
        {
          title: 'Flujo de Trabajo',
          key: 'flujoTrabajoRiego',
          url: '/Apps/Learning/Riego/FlujoTrabajo',
          icon: 'icmn icmn-make-group',
        },
        {
          title: 'Bodega',
          key: 'unityAlimentacionBodega',
          url: '/Apps/Learning/Alimentacion/Bodega',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Cocina',
          key: 'unityAlimentacionCocina',
          url: '/Apps/Learning/Alimentacion/Cocina',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Comedor',
          key: 'unityAlimentacionComedor',
          url: '/Apps/Learning/Alimentacion/Comedor',
          icon: 'icmn icmn-file-text2',
        },
        {
          title: 'Ejecución',
          key: 'execution',
          icon: 'icmn icmn-make-group',
          children: [
            {
              title: 'Recepción',
              key: 'recepcion',
              url: '/Apps/Learning/Logistics/Operacion/Recepcion',
              icon: 'icmn icmn-make-group',
            },
            {
              title: 'Clasificación y Almacenaje',
              key: 'clasificacionAlmacenaje',
              url: '/Apps/Learning/Logistics/Operacion/ClasificacionAlmacenaje',
              icon: 'icmn icmn-make-group',
            },
            {
              title: 'Picking',
              key: 'picking',
              url: '/Apps/Learning/Logistics/Operacion/Picking',
              icon: 'icmn icmn-make-group',
            },
            {
              title: 'Despacho',
              key: 'despacho',
              url: '/Apps/Learning/Logistics/Operacion/Despacho',
              icon: 'icmn icmn-make-group',
            },
          ],
        },
      ],
    },
    {
      title: 'Actividad de Vestimenta',
      key: 'unityVestimenta',
      url: '/Apps/Learning/Edification/Vestimenta',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Actividad de Partes y Piezas',
      key: 'unityPartesyPiezas',
      url: '/Apps/Learning/Edification/PartesyPiezas',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Actividad de Construcción',
      key: 'unityConstruccion',
      url: '/Apps/Learning/Edification/Construccion',
      icon: 'icmn icmn-file-text2',
    },
    {
      title: 'Capacitación Riesgos',
      key: 'unityRiesgosAA',
      url: '/Apps/Learning/Riesgos/AguasAndinas',
      icon: 'icmn icmn-file-text2',
    },

    // Test apps
    {
      title: 'App test',
      key: 'appTest',
      url: '/Apps/Learning/Test/Test1',
      icon: 'icmn icmn-cogs',
    },
    // Reportes externos
    {
      title: 'Reporte Agrotec',
      key: 'agrotecReport',
      url: '/External/AgrotecReport',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Reporte Elogos',
      key: 'elogosReport',
      url: '/External/ElogosReport',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Reporte Alimentación',
      key: 'alimentacionReport',
      url: '/External/AlimentacionReport',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Reporte Rigger',
      key: 'riggerReport',
      url: '/External/RiggerReport',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Reporte Essbio',
      key: 'essbioReport',
      url: '/External/EssbioReport',
      icon: 'icmn icmn-cogs',
    },
    {
      title: 'Reporte Aguas Andinas',
      key: 'aguasAndinasReport',
      url: '/External/AguasAndinasReport',
      icon: 'icmn icmn-cogs',
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Dashboard',
      key: 'dashboardAlpha',
      url: '/dashboard/alpha',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Productos',
      key: 'prods',
      url: '/Maintainers/UserTripProducts',
      icon: 'icmn icmn-books',
      disabled: true,
    },
  ]
}
