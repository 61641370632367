import firebase from 'firebase/app'
import 'firebase/auth'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, logout, forget, currentAccount, askForMoreData } from 'services/user'
// import { currentAccount, askForMoreData, getViewsData } from 'services/user'
import actions from './actions'

let firebaseObject = []

export function* LOGIN({ payload }) {
  console.log('step', window.localStorage.getItem('loginStep'))
  const { email, password, project } = payload
  if (!project) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
    const success = yield call(login, email, password)
    if (success) {
      window.localStorage.setItem('loginStep', '1')
      // Verificar si es multiproyecto
      const userData = yield call(currentAccount)
      let fireMulti = ''
      const projectsArray = []
      const ref = yield firebase.database().ref(`Clients/${userData.uid}`)

      const dataSnapshot = yield new Promise(resolve => {
        ref.once('value', resolve)
      })
      // cambiar firebaseData por clientData (nuevo formato)
      const clientData = dataSnapshot.val()
      let firebaseData = {}

      if (Array.isArray(clientData)) {
        // Es arreglo de Objetos
        // Se deben agrupar los datos Firebase de todas las instacias
        firebaseData = []
        clientData.map(projectItem => {
          firebaseData.push(projectItem.Firebase)
          return true
        })

        firebaseObject = firebaseData
        fireMulti = 'array'
        firebaseData.map(projectItem => {
          if (projectItem.projectId) {
            projectsArray.push(projectItem.projectId)
            return true
          }
          return true
        })
      } else {
        firebaseData = clientData.Firebase
        console.log('firebase data es objeto or unexpected', firebaseData)
        fireMulti = 'object'
      }

      // Si solo tiene un proyecto
      if (fireMulti === 'object') {
        // loginStep = 0
        window.localStorage.setItem('loginStep', '0')
        window.localStorage.setItem('projectRef', '')
        console.log('1 proyecto')
        yield put({
          type: 'user/LOAD_CURRENT_ACCOUNT',
          payload: null,
        })
        notification.success({
          message: 'Acceso concedido',
          description: 'Haz ingresado con éxito al Administrador de Soluciones VR/AR',
        })
      } else {
        console.log('Multiproyecto')
        window.localStorage.setItem('projectRef', '')
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            hiddeList: false,
            projectsList: projectsArray,
            projectsFirebase: firebaseData,
            userDisabled: true,
            passDisabled: true,
          },
        })
      }
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          hiddeList: true,
        },
      })
    }
  } else {
    let firebaseConnection = ''
    let projectIndex = 0
    firebaseObject.map(firebaseItem => {
      if (firebaseItem.projectId === project) {
        firebaseConnection = firebaseItem
        window.localStorage.setItem('projectRef', `${projectIndex.toString()}/`)
        return true
      }
      projectIndex += 1
      return true
    })
    // loginStep = 0
    window.localStorage.setItem('loginStep', '0')
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      payload: {
        firebaseArray: firebaseConnection,
      },
    })
    notification.success({
      message: 'Acceso concedido',
      description: 'Haz ingresado con éxito al Administrador de Soluciones VR/AR',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT({ payload }) {
  // yield call(logout)
  const loginStep = window.localStorage.getItem('loginStep')
  console.log('step current account', loginStep)
  let firebaseArray = []
  if (payload) {
    firebaseArray = Object.values(payload)
  }
  if (firebaseArray.length === 0) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: true,
      },
    })
  }
  const firebaseConnection = firebaseArray[0]
  console.log('firebase array', firebaseArray)
  console.log('firebase CONX', firebaseConnection)
  const response = yield call(currentAccount)
  if (loginStep === '1') {
    yield call(logout)
    window.localStorage.setItem('loginStep', '0')
  } else {
    console.log('response', response)
    if (response) {
      // Obtener más datos de usuario para derivarlo a su db
      const moreData = yield call(askForMoreData, response.uid, firebaseConnection)
      console.log(moreData)
      console.log(global.secondary.name)
      if (moreData.Nombre !== undefined) {
        window.localStorage.setItem('loginStep', '0')
        // loginStep = 0
        const { uid: id, email, photoURL: avatar } = response
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id,
            name: moreData.Nombre,
            email,
            avatar,
            role: moreData.Rol,
            authorized: true,
          },
        })
      }
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      userDisabled: false,
      passDisabled: false,
      hiddeList: true,
    },
  })
}

export function* FORGET({ payload }) {
  console.log('FORGET')
  const { username } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(forget, username)
  if (success) {
    notification.success({
      message: 'Email válido',
      description: `Hemos enviado un mensaje de recuperación de contraseña a ${payload.username}`,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  // yield call(logout)
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGET, FORGET),
    LOAD_CURRENT_ACCOUNT({ payload: null }), // run once on app load to check user auth
  ])
}
