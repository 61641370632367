import antdData from 'antd/lib/locale-provider/fr_FR'

const messages = {
  'topBar.issuesHistory': 'Historique des problèmes',
  'topBar.projectManagement': 'Gestion de projet',
  'topBar.typeToSearch': 'Saisissez votre recherche',
  'topBar.buyNow': 'Acheter un paquet',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Bonjour',
  'topBar.profileMenu.billingPlan': 'Plan de facturation',
  'topBar.profileMenu.role': 'Rôle',
  'topBar.profileMenu.email': 'Courriel',
  'topBar.profileMenu.phone': 'Téléphone',
  'topBar.profileMenu.editProfile': 'Modifier le profil',
  'topBar.profileMenu.logout': 'Déconnexion',
  'breadcrumb.home': 'Accueil',

  'alpha.activeUsers': 'Utilisateurs actifs',
  'alpha.totalUsers': 'Total des utilisateurs',
  'alpha.addedProducts': 'Produits ajoutés',
  'alpha.topModels': 'Principaux modèles',
  'alpha.topUsers': 'Principaux utilisateurs',
  'alpha.versionLog': 'Historique des versions',
  'alpha.thisWeek': 'Cete semaine',
  'alpha.thisMonth': 'Ce mois-ci',
  'alpha.last3Months': 'Les derniers trois mois',
  'alpha.more': 'Plus',
  'alpha.sesPerWeek': 'Sessions par semaine',
  'alpha.meanTimePerSes': 'Temps moyen par session',
  'alpha.sessions': 'Sessions',
  'alpha.givenSessions': 'Sessions jouées',
  'alpha.generalMax': 'Score maximum (tous les utilisateurs)',
  'alpha.generalAvg': 'Score moyen (tous les utilisateurs)',

  'menu.users': 'Utilisateurs',
  'menu.models': 'Modèles',
  'menu.products': 'Produits',
  'menu.projects': 'Projets',
  'menu.project': 'Projet',
  'menu.courses': 'Cours',

  'table.name': 'Nom',
  'table.company': 'Entreprise',
  'table.email': 'Courriel',
  'table.sessions': 'Sessions',
  'table.sesMean': 'Score moyen par session',
  'table.lastAccess': 'Dernier accès',
  'table.photo': 'Photo',
  'table.category': 'Catégorie',
  'table.profile': 'Profil',
  'table.visits': 'Visites',
  'table.visitors': 'Visiteurs',
  'table.ratioPerUser': 'Ratio par utilisateur',
  'table.user': 'Utilisateur',
  'table.products': 'Produits',
  'table.action': 'Action',
  'table.edit': 'Modifier',
  'table.delete': 'Supprimer',
  'table.deleteSure': 'Vous êtes sûr?',
  'table.seeResults': 'Voir les résultats',
  'table.password': 'Mot de passe',

  'global.download': 'Télécharger',
  'global.fullscreen': 'Plein écran',

  'users.title': 'Liste des utilisateurs',
  'users.addUser': 'Ajouter un utilisateur',
  'users.editUser': 'Modifier un utilisateur',
  'models.title': 'Liste de modèles',
  'products.title': 'Liste de produits',
  'allTrips.title': 'Liste de projets',
  'userTrips.title': 'Liste du projet',
  'userTripProducts.title': 'Liste des produits du projet',
  'studentProjectPage.title': 'Liste des activités',

  'studentDetail.breadcrumb': 'Détails de l’étudiant',
  'studentDetail.title': 'Liste des sessions pour',

  'sessions.sessions': 'Sessions :',

  'modal.help.name': 'Saisissez le nom de l’utilisateur',
  'modal.help.email':
    'Saisissez l’adresse électronique. Elle sera utilisée pour se connecter dans l’application',
  'modal.help.password': 'Saisissez le mot de passe. Il doit comporter au moins 6 caractères.',

  'login.support': 'Support',
  'login.back': 'Retour à Imova',
  'login.title': 'RESPONSABLE DES SOLUTIONS VR/AR ',
  'login.emailHelp': 'Saisissez votre adresse électronique',
  'login.passHelp': 'Saisissez votre mot de passe',
  'login.rememberMe': 'Se souvenir de moi dans cet appareil',
  'login.forgot': 'Vous avez oublié votre mot de passe ?',
  'login.button': 'Se connecter',

  'forgot.title': 'Créer un nouveau mot de passe',
  'forgot.back': 'Retour à la connexion',
  'forgot.button': 'Créer un nouveau mot de passe',

  'notification.denied': 'Accès refusé',
  'notification.deniedMessage': 'Nom d’utilisateur ou mot de passe incorrect',
  'notification.invalidMail': 'Adresse électronique invalide',
  'notification.invalidMailMessage': 'Non enregistrée ou supprimée. Veuillez réessayer.',
  'notification.granted': 'Accès accordé',
  'notification.grantedMessage':
    'Vous vous êtes connecté avec succès au gestionnaire de solutions VR/AR.',
  'notification.validMail': 'Adresse électronique valide',
  'notification.validMailMessage':
    'Nous avons envoyé un courriel de récupération du mot de passe à',

  PuntajeGlobal: 'Score global',
  PuntajePromedio: 'Score moyen',
  TiempoPromedio: 'Temps moyen',
  SesionesUsadas: 'Sessions utilisées',
  SesionesContratadas: 'Sessions disponibles',
  TimestampInicio: 'Valable du',
  TimestampFinal: 'Valable jusqu’au',
  Promedio: 'Score moyen',
  Notas: 'Sessions',
  LastLogin: 'Dernière connexion',
  user: 'Adresse électronique',
  Timestamp: 'Valable du',
  CantidadSesiones: 'Sessions',
  perfil: 'Description du profil',
  estado: 'État du profil',
  Count: 'Sessions',
  Meantime: 'Temps moyen par session',
  Compania: 'Entreprise',
  ConteoSesiones: 'Compter les sessions',
  ClientesPerdidos: 'Clients perdus',
  PreguntasCorrectas: 'Réponses correctes',
  PreguntasIncorrectas: 'Réponses incorrectes',

  Nombre: 'Nom',
  Puntaje: 'Score',
  Sesiones: 'Sessions',
  Fecha: 'Date',

  Actividad: 'Activité',
  Sección: 'Section',
  Estado: 'État',
  Habilitado: 'Disponible',
  Duración: 'durée',

  back: 'Retour',
}

export default {
  locale: 'fr-FR',
  antdData,
  messages,
}
